<template>
  <v-card outlined class="px-4 mx-4 mb-4 py-4" :key="plan.key">
    <v-row align="center" class="px-2 py-0">
      <v-col
        class="d-flex justify-space-between align-center py-2"
        align-self="start"
      >
        <v-row align="center" class="py-0" no-gutters>
          <v-col
            cols="12"
            lg="10"
            md="12"
            class="d-flex align-center py-0 px-0"
          >
            <Logo
              v-if="plan.plan && $vuetify.breakpoint.smAndUp"
              :product="plan.plan.short_code || 'plans'"
              height="60"
              width="60"
              no-margins
              :products="plan.plan.short_code === 'sign_automation'"
              :class="`mr-4 ${isDisabled ? 'black-and-white' : ''} mt-3`"
            />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="capitalize font-weight-medium text-h5 d-flex align-center break-word"
                >
                  {{ planName }}
                  <span v-if="$vuetify.breakpoint.mdAndUp || !isService">
                    <StatusBadge
                      v-if="statusDates.end_trial"
                      :status="status"
                      :valid-status="validStatus"
                      :end-trial="statusDates.end_trial"
                      class-style="ml-2"
                    />
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="
                    $vuetify.breakpoint.smAndUp &&
                    !hasIntermediaryPartner &&
                    !isService
                  "
                  class="capitalize text-body-1"
                >
                  {{ summary }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="
                    $vuetify.breakpoint.smAndUp &&
                    !hasIntermediaryPartner &&
                    !isService
                  "
                  class="capitalize text-body-1"
                >
                  SKU - {{ planSKU }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            cols="12"
            v-if="!isSelfCheckout"
            sm="12"
            md="12"
            lg="2"
            :class="`${
              $vuetify.breakpoint.lgAndUp ? 'd-flex justify-end' : ''
            }`"
          >
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row align="stretch">
      <v-col>
        <v-card flat tile>
          <v-card-title class="text-body-1 font-weight-medium py-5">
            {{
              isService ? $t("common.serviceDetails") : $t("common.planDetails")
            }}
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-list dense class="pa-0">
              <v-list-item
                v-for="(field, i) in planDetails"
                :key="i"
                class="pa-0 ma-0"
              >
                <v-list-item-title
                  class="pa-0 ma-0 text-body-2 d-flex align-center flex-wrap"
                >
                  <span v-if="field.title" v-text="field.title" />
                  <span
                    :data-testid="`${field.key}-info`"
                    v-if="field.description"
                    v-text="field.description"
                    class="font-weight-regular ml-1"
                  />
                  <v-tooltip
                    v-if="
                      ('company_plan_total_value' === field.key ||
                        (isService && 'company_plan_value' === field.key)) &&
                      discount > 0
                    "
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        x-small
                        color="success"
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ $t("companyPlansInfo.savingsOf") }}
                        {{ discountValue }}
                      </v-chip>
                    </template>
                    <span
                      >{{ $t("companyPlansInfo.discountApplied") }}
                      {{ percentageDiscount }}</span
                    >
                  </v-tooltip>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
          <PurchaseNote
            :note="notes"
            :key="purchaseKey"
            :plan-key="purchaseKey"
            @saved="localUpdate"
            @close="addNote = false"
          />
        </v-card>
      </v-col>
      <v-col>
        <v-card flat tile>
          <v-card-title
            :class="`text-body-1 font-weight-medium break-word flex-wrap ${
              isConecta ? '' : 'mt-3'
            }`"
          >
            {{ $t("companyPlansInfo.billingInfo") }}
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-list dense class="pa-0">
              <div v-for="(field, i) in paymentFields" :key="i">
                <!-- LINK INFORMATION -->
                <v-list-item
                  v-if="canEdit(field.key)"
                  class="pa-0 ma-0"
                  link
                  @click="showEditDialog(field.key)"
                >
                  <v-list-item-title
                    :class="`pa-0 ma-0 text-body-2 d-flex align-center ${
                      field.key === 'end_trial' && expiredTrial
                        ? 'error--text'
                        : ''
                    }`"
                  >
                    <span v-if="field.title" v-text="field.title" />
                    <span
                      :data-testid="`${field.key}-info`"
                      v-if="field.description"
                      v-text="field.description"
                      class="font-weight-regular ml-1"
                    />
                  </v-list-item-title>
                  <v-list-item-action v-if="canEdit(field.key)" class="pr-2">
                    <v-icon v-text="'mdi-pencil'" />
                  </v-list-item-action>
                </v-list-item>
                <!-- NORMAL INFORMATION -->
                <v-list-item v-else class="pa-0 ma-0">
                  <v-list-item-title
                    :class="`pa-0 ma-0 text-body-2 d-flex align-center`"
                  >
                    <span v-if="field.title" v-text="field.title" />
                    <span
                      :data-testid="`${field.key}-info`"
                      v-if="field.description"
                      v-text="field.description"
                      class="font-weight-regular ml-1"
                    />
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="start" v-if="!isSelfCheckout && notes && isConecta">
      <v-col>
        <v-card outlined>
          <v-card-title class="text-body-1 font-weight-medium py-5">
            Observações
          </v-card-title>
          <v-divider />
          <PurchaseNote
            :note="notes"
            :key="purchaseKey"
            :plan-key="purchaseKey"
            @saved="localUpdate"
            list-item
          />
        </v-card>
      </v-col>
    </v-row>
    <EditPlanDate
      id="end_trial"
      :planKey="plan.key"
      :show="showEditTrial"
      :disabledAction="!newTrialDate"
      title="Editar data de encerramento de trial"
      labelInput="Encerramento de trial"
      actionText="Aplicar nova data"
      :description="`Escolha uma nova data para o encerramento de TRIAL para ${planName}`"
      :disableField="plan.status != STATUS.TRIAL"
      :date="billingDates.end_trial"
      :mainDomain="selectedResellerClient.main_domain"
      @closeDialog="closeDialog"
      @updatePlan="updatePlan"
      @showSnackbarToast="showSnackbarToast"
    />
    <EditPlanDate
      id="renewal_date"
      :planKey="plan.key"
      :show="showEditRenewalDate"
      :disabledAction="!newRenewalDate"
      title="Editar data de renovação de contrato"
      labelInput="Renovação de contrato"
      actionText="Aplicar nova data"
      :description="`Escolha uma nova data para renovação de contrato para o plano ${planName}`"
      :disableField="plan.status === STATUS.TRIAL"
      :date="billingDates.renewal_date"
      :mainDomain="selectedResellerClient.main_domain"
      @closeDialog="closeDialog"
      @updatePlan="updatePlan"
      @showSnackbarToast="showSnackbarToast"
    />
    <Snackbar
      :show="showSnackbar"
      :message="snackbarText"
      :color="snackbarType"
      @close="showSnackbar = false"
    />
    <SimpleDialog
      :title="$t('companyPlansInfo.changePaymentMethod')"
      :show="showEditPaymentMethod"
      :showLoading="localLoading"
      showCancel
      @close="showEditPaymentMethod = false"
      @action="editPlan"
      finalizar
      aqui
      ananaha
      width="600"
    >
      <template v-slot:body>
        <span
          >{{ $t("companyPlansInfo.paymentChangedTo") }}
          <strong
            >{{
              $t(
                `selfCheckout.${
                  planPaymentMethod == "bankSlip" ? "creditCard" : "bankSlip"
                }`
              )
            }}
          </strong>
          <TooltipIcon
            color="primary"
            :label="$t('common.aboutChangePayment')"
            max-width="400"
            icon="mdi-information"
            size="20"
          />
        </span>
        <Alert
          color="warning"
          dense
          class="mt-2"
          v-if="planPaymentMethod === 'bankSlip'"
        >
          <span class="text-body-2"
            >{{ $t("common.alertAboutChangePayment") }}
          </span>
        </Alert>
      </template>
    </SimpleDialog>
  </v-card>
</template>
<script>
import { logBtnEvent } from "@/helpers/services/registerAnalytics";
import { mapActions, mapGetters, mapMutations } from "vuex";

import { STATUS, SUBSCRIPTIONS } from "@/helpers/variables/backendConstants.js";

import PurchaseNote from "@/components/reseller/dialogs/PurchaseNote.vue";

import {
  formatModuleName,
  formatMoney,
  formatDate,
  formatDateForDatabase,
  hasDecimalPart,
} from "@/helpers/services/utils";

import {
  getPriceByPeriod,
  getTotalPlanPrice,
  getDiscountValue,
  getTotalServicePriceByInstallment,
  getTotalServicePrice,
  getServiceDiscountValue,
} from "@/helpers/services/billing";

import {
  planPeriodicDescription,
  translatePeriods,
  planPayment,
  planPeriodic,
  defaultDiscounts,
} from "@/helpers/variables/translateString";
import { getAdvancedLogoutStatus } from "@/helpers/services/conectaControl";

import StatusBadge from "@/components/purchase/StatusBadge";

import * as moment from "moment";
import EditPlanDate from "../dialogs/EditPlanDate.vue";
export default {
  name: "MoreInfoCardReseller",

  components: { PurchaseNote, StatusBadge, EditPlanDate },

  props: {
    clientName: { type: String, default: "" },
    loading: { type: Boolean, default: false },
    logo: { type: String },
    newService: Boolean,
    plan: { type: Object, required: true },
    selfCheckout: { type: Boolean, default: false },
    disablePurschaseActions: { type: Boolean, default: false },
  },

  data() {
    return {
      componentKey: 0,
      addNote: false,
      localNote: "",
      STATUS,
      translatePeriods,
      planPeriodic,
      planPeriodicDescription,
      formatMoney,
      showCurrentlyPlan: false,
      showLicensesExplanation: false,
      showEditTrial: false,
      planPayment,
      newTrialDate: moment().format("YYYY-MM-DD"),
      datePicker: false,
      planInitBillingDate: null,
      localLoading: false,
      defaultDiscounts,
      showEditRenewalDate: false,
      newRenewalDate: moment().format("YYYY-MM-DD"),
      showSnackbar: false,
      snackbarType: "success",
      snackbarText: "",
      showEditPaymentMethod: false,
    };
  },
  computed: {
    ...mapGetters([
      "hasIntermediaryPartner",
      "isSelfCheckout",
      "usersNumber",
      "selectedResellerClient",
      "isConecta",
    ]),

    /**
     * Detect loading state
     * @return {Boolean}
     */
    isLoading() {
      return this.localLoading || this.loading;
    },

    /**
     * Original plan name
     * @return {String}
     */
    planName() {
      return this.plan.plan.name;
    },

    /**
     * Original plan name
     * @return {String}
     */
    planSKU() {
      return this.plan.plan.sku;
    },

    /**
     * Original plan price
     * @return {Number}
     */
    planPrice() {
      return this.plan.plan.price;
    },

    /**
     * Plan payment
     * @return {Number}
     */
    planPaymentMethod() {
      return this.plan.payment_method === "BANK_SLIP"
        ? "bankSlip"
        : "creditCard";
    },

    /**
     * A summary to display bellow the plan name
     * @return {String}
     */
    summary() {
      let periodAndPrice = "";

      if (this.isAnnual) {
        periodAndPrice = `${this.$t("common.annualPlan")} - ${
          this.totalPriceByPeriod
        }`;
      } else if (this.isFlex) {
        periodAndPrice = `${this.$t("common.flexPlan")} - ${
          this.totalPriceByPeriod
        }`;
      } else if (this.isFree) {
        return this.$t("common.freePlan");
      } else {
        periodAndPrice = `Gratuito até ${formatDate(
          this.statusDates.end_trial
        )}`;
      }
      periodAndPrice += ` - ${this.licensesCharged} ${
        this.licensesCharged === 1
          ? this.$t("common.license")
          : this.$t("common.licenses")
      }`;

      return periodAndPrice;
    },

    /**
     * Current company-plan key
     * @return {String}
     */
    purchaseKey() {
      return this.plan.key;
    },

    /**
     * The licences number that count on financial transactions (charged licenses)
     * @return {Boolean}
     */
    licensesCharged() {
      return this.isAnnual ? this.maxLicenses : this.licenses;
    },

    /**
     * The notes left by Conecta users about some company-plan
     * @return {String}
     */
    notes() {
      return this.plan.notes;
    },

    /**
     * The type of subscription the plan was purchased with
     * @return {String}
     */
    subscriptionType() {
      return this.plan.subscription_type;
    },

    /**
     * The status the plan was purchased with
     * @return {String}
     */
    status() {
      return this.plan.status;
    },

    /**
     * Verify if it is a service
     * @return {Boolean}
     */
    isService() {
      return (
        this.subscriptionType == SUBSCRIPTIONS.ONCE &&
        this.status == STATUS.DISABLED
      );
    },

    /**
     * The price the plan was purchased with
     * @return {Number}
     */
    price() {
      return this.plan.price || 0;
    },

    /**
     * The discount the plan was purchased with
     * @return {Number}
     */
    discount() {
      return this.plan.discount || 0;
    },

    /**
     * The licenses number of a company-plan
     * @return {Number}
     */
    licenses() {
      return this.plan.licenses;
    },

    /**
     * Licenses that are charged in total price
     * @return {Number}
     */
    chargedLicenses() {
      return this.isAnnual ? this.plan.max_licenses : this.licenses;
    },

    /**
     * The final total price of the plan
     * @return {Number}
     */
    totalPrice() {
      const discount = this.discount;
      return getTotalPlanPrice(
        this.chargedLicenses,
        this.subscriptionType,
        discount,
        this.planPrice
      );
    },

    /**
     * The price and subscription type to display
     * @return {String} In the format price/subscription type. Eg.: (R$2,00/ano)
     */
    totalPriceByPeriod() {
      return this.isFree
        ? "R$ 0,00"
        : `${formatMoney(this.totalPrice)}
        ${this.isService ? "" : "/" + this.$t(this.subscriptionType)}`;
    },

    /**
     * If the trial is expired
     * @return {Boolean}
     */
    expiredTrial() {
      return this.validStatus === STATUS.TRIAL_EXPIRED;
    },

    /**
     * If the plan is annual
     * @return {Boolean}
     */
    isAnnual() {
      return this.subscriptionType === SUBSCRIPTIONS.ANNUAL && !this.isTrial;
    },

    /**
     * If the plan is flex
     * @return {Boolean}
     */
    isFlex() {
      return this.subscriptionType === SUBSCRIPTIONS.FLEX && !this.isTrial;
    },

    /**
     * If the plan is trial
     * @return {Boolean}
     */
    isTrial() {
      return this.status === STATUS.TRIAL;
    },

    isFree() {
      return this.subscriptionType === SUBSCRIPTIONS.FREE;
    },

    /**
     * If the plan is in disabled status
     * @return {Boolean}
     */
    isDisabled() {
      return this.status === STATUS.DISABLED;
    },

    /**
     * If the plan is in active status
     * @return {Boolean}
     */
    isActive() {
      return this.status === STATUS.ACTIVE;
    },

    /**
     * Get the company domain
     * @return {String}
     */
    domain() {
      return this.$route.query.domain;
    },

    /**
     * Get the company-plan key
     * @return {String}
     */
    key() {
      return this.$route.query.key;
    },

    /**
     * Get the maximum of licenses of that plan
     * @return {Number}
     */
    maxLicenses() {
      return this.isAnnual && this.plan.max_licenses
        ? this.plan.max_licenses
        : 0;
    },

    /**
     * If any discount
     * @return {Boolean} A value true or false depends if this plan has discount value
     */
    hasDiscount() {
      return this.discount > 0;
    },

    /**
     * If any installment
     * @return {Boolean} A value true or false depends if this plan has installment value
     */
    hasInstallments() {
      return this.plan.installments && this.plan.installments > 1;
    },

    /**
     * @return {String} Return a text with de installment number and price
     */
    installmentText() {
      return this.hasInstallments
        ? `${this.plan.installments} ${
            this.plan.installments == 1
              ? this.$t("common.installment")
              : this.$t("common.installments")
          } de ${formatMoney(
            getTotalServicePriceByInstallment(
              this.plan.installments,
              this.discount,
              this.planPrice
            )
          )}`
        : "";
    },

    /**
     * The total discount in percent
     * @return {String} The discount in percentage. Eg.: (16%)
     */
    percentageDiscount() {
      const totalDiscount = this.discount * 100;
      return `${totalDiscount.toFixed(hasDecimalPart(totalDiscount) ? 2 : 0)}%`;
    },

    /**
     * The total discount in R$
     * @return {String} The discount price in money. Eg.: (R$2,00)
     */
    discountValue() {
      if (this.isService) {
        return `${formatMoney(
          getServiceDiscountValue(this.planPrice, this.discount)
        )}`;
      } else {
        return `${formatMoney(
          getDiscountValue(
            this.chargedLicenses,
            this.subscriptionType,
            this.discount,
            this.planPrice
          )
        )}`;
      }
    },

    /**
     * The original plan price by user and period
     * @return {String} in format (Eg.: R$8,50/usuário/mês)
     */
    planPriceByPeriod() {
      const price = getPriceByPeriod(this.subscriptionType, this.planPrice, 0);
      if (this.isAnnual) {
        return `${formatMoney(price)}/${this.$t("common.user")}/${this.$t(
          "ANNUAL"
        )}`;
      }
      return `${formatMoney(price)}/${this.$t("common.user")}/${this.$t(
        "FLEX"
      )}`;
    },

    /**
     * The dates the purchased plan has any kind of billing
     * @return {String} in format (Eg.: R$8,50/usuário/mês or Gratuito por 14 dias)
     */
    totalWithDiscount() {
      if (this.isService) {
        return `${formatMoney(
          getTotalServicePrice(this.planPrice, this.discount)
        )}`;
      } else {
        const price = getPriceByPeriod(
          this.subscriptionType,
          this.planPrice,
          this.discount
        );
        if (this.isAnnual || this.isFlex) {
          return `${formatMoney(price)} /${this.$t("common.user")}/${this.$t(
            this.subscriptionType
          )}`;
        }
        return this.$t("companyPlansInfo.TwoWeeksFree");
      }
    },

    /**
     * The dates the purchased plan has any kind of billing
     * @return {Object}
     */
    billingDates() {
      return {
        init_date: this.plan.init_date,
        init_billing: this.plan.init_billing,
        next_billing: this.plan.next_billing,
        init_transaction: this.plan.init_transaction,
        end_transaction: this.plan.end_transaction,
        renewal_date: this.plan.renewal_date,
      };
    },

    /**
     * The dates the purchased plan changed to a status
     * @return {Object}
     */
    statusDates() {
      return {
        init_date: this.plan.init_date,
        end_date: this.plan.end_date,
        end_trial: this.plan.end_trial,
      };
    },

    validStatus() {
      return this.plan.valid_status;
    },

    /**
     * Items of "Detalhes do plano"
     * @return {Array} A list with items to display in "Detalhes do plano" col.
     */
    planDetails() {
      return [
        {
          show: this.licenses && !this.isService,
          title: this.$t("companyPlansInfo.licensesNumber"),
          description: `${this.licenses} ${this.$t("common.licenses")}`,
          key: "licenses_number",
        },
        {
          show: this.isAnnual,
          title: this.$t("companyPlansInfo.maxLicensesNumber"),
          description: `${this.maxLicenses} ${this.$t("common.licenses")}`,
          key: "max_licenses_number",
        },
        {
          show:
            (this.isSelfCheckout
              ? (this.hasDiscount && !this.hasIntermediaryPartner) ||
                this.isService
              : this.isConecta) &&
            !this.isTrial &&
            !this.hasIntermediaryPartner &&
            !this.isFree,
          title: `${
            this.isService
              ? this.$t("companyPlansInfo.originalPrice")
              : this.$t("companyPlansInfo.originalPricePerLicense")
          }:`,
          description: `${formatMoney(this.planPrice)}${
            this.isService ? "" : this.$t("companyPlansInfo.userMonth")
          }`,
          key: "original_price",
        },
        {
          show:
            !this.isSelfCheckout &&
            !this.isTrial &&
            !this.hasIntermediaryPartner &&
            this.isConecta &&
            !this.isService &&
            !this.isFree,
          title: this.$t("companyPlansInfo.originalPricePeriod"),
          description: this.planPriceByPeriod,
          key: "original_price_by_period",
        },
        {
          show:
            this.hasDiscount && !this.isTrial && !this.hasIntermediaryPartner,
          title: `${
            this.isService
              ? this.$t("companyPlansInfo.priceWithDiscount")
              : this.$t("companyPlansInfo.pricePerLicenseWithDiscount")
          }`,
          description: `${this.totalWithDiscount}`,
          key: "company_plan_value",
        },
        {
          show:
            !this.isTrial && !this.hasIntermediaryPartner && !this.isService,
          title: this.$t("companyPlansInfo.totalPrice"),
          description: `${this.totalPriceByPeriod}`,
          key: "company_plan_total_value",
        },
        {
          show:
            this.isService &&
            !this.hasIntermediaryPartner &&
            this.hasInstallments,
          title: this.$t("companyPlansInfo.installments"),
          description: this.installmentText,
          key: "installmens",
        },
        {
          show:
            !this.isTrial && this.hasDiscount && !this.hasIntermediaryPartner,
          title: this.$t("companyPlansInfo.appliedDiscounts"),
          description: this.percentageDiscount,
          key: "discount",
        },
      ].filter((item) => item.show);
    },

    /**
     * Items of "Informações de cobrança"
     * @return {Array} A list with items to display in "Informações de cobrança" col.
     */
    paymentFields() {
      return [
        {
          show: this.billingDates.init_date,
          title: `${
            this.isService
              ? this.$t("companyPlansInfo.purchaseDate")
              : this.$t("companyPlansInfo.creationDate")
          }`,
          description: formatDate(this.billingDates.init_date),
          key: "init_access",
        },
        {
          show:
            this.billingDates.init_billing &&
            !this.isSelfCheckout &&
            !this.isService &&
            !this.isFree,
          title: this.$t("companyPlansInfo.initBilling"),
          description: formatDate(this.billingDates.init_billing),
          key: "init_billing",
        },
        {
          show: this.statusDates.end_trial
            ? !this.isSelfCheckout ||
              (this.isSelfCheckout && this.isTrial && !this.isService)
            : false,
          title: this.$t("companyPlansInfo.endTrial"),
          description: formatDate(this.statusDates.end_trial),
          key: "end_trial",
        },
        {
          show:
            this.billingDates.init_transaction &&
            !this.isService &&
            !this.isFree,
          title: this.$t("companyPlansInfo.initTransaction"),
          description: formatDate(this.billingDates.init_transaction),
          key: "init_transaction",
        },
        {
          show:
            this.billingDates.end_transaction &&
            !this.isService &&
            !this.isFree,
          title: this.$t("companyPlansInfo.endTransaction"),
          description: formatDate(this.billingDates.end_transaction),
          key: "end_transaction",
        },
        {
          show:
            this.billingDates.next_billing && !this.isService && !this.isFree,
          title: this.$t("companyPlansInfo.nextBilling"),
          description: formatDate(this.billingDates.next_billing),
          key: "next_billing",
        },
        {
          show:
            this.billingDates.next_billing && !this.isService && !this.isFree,
          title: this.$t("companyPlansInfo.renewalDate"),
          description: formatDate(this.billingDates.renewal_date),
          key: "renewal_date",
        },
        {
          show: this.statusDates.end_date && this.isDisabled && !this.isService,
          title: this.$t("companyPlansInfo.endDate"),
          description: formatDate(this.statusDates.end_date),
          key: "end_date",
        },
        {
          show:
            this.statusDates.next_billing && this.isActive && !this.isService,
          title: this.$t("companyPlansInfo.autoRenewal"),
          description: null,
          isRenovation: true,
        },
        {
          show: !this.isFree && !this.isService && !this.isTrial,
          title: this.$t("companyPlansInfo.paymentMethod"),
          description: this.$t(`selfCheckout.${this.planPaymentMethod}`),
          key: "payment_method",
        },
      ].filter((item) => item.show);
    },
  },
  watch: {
    showEditTrial() {
      if (this.showEditTrial) {
        this.newTrialDate = formatDateForDatabase(this.statusDates.end_trial);
      }
    },

    plan() {
      if (this.notes) {
        this.localUpdate(this.plan.notes);
      }
    },
  },
  methods: {
    ...mapMutations(["setSelectedPlan"]),
    ...mapActions(["updateHiredPlan"]),

    formatModuleName,

    getAdvancedLogoutStatus,

    formatDate,

    formatDateForDatabase,

    forceRerender() {
      this.componentKey += 1;
    },

    localUpdate(note) {
      this.localNote = note;
      this.plan.notes = this.localNote;
    },

    canEdit(key) {
      const canEdit = !this.isSelfCheckout && this.isConecta;
      if (canEdit) {
        if (key === "end_trial" && this.isTrial) {
          return true;
        }
        return !!["renewal_date", "payment_method"].includes(key);
      }
      return false;
    },

    setCurrentPlanDetails() {
      logBtnEvent({
        label: this.$t("companyPlansInfo.seePlansDetail"),
        action: "open_plan_information",
      });
      this.showCurrentlyPlan = true;
    },

    showPlan() {
      let type = "";
      if (this.plan.status === "TRIAL") {
        type = "TRIAL";
      } else if (this.plan.status === "DISABLED") {
        type = "DISABLED";
      } else {
        type = this.plan.subscription_type;
      }
      let queries_to_this_plan = {
        domain: this.domain,
        key: this.key,
        plan: this.plan.plan.key,
        type: type,
      };
      if (this.plan.max_licenses) {
        queries_to_this_plan.max_licenses = this.plan.max_licenses;
      }
      if (this.isSelfCheckout) {
        this.$router.push({
          path: "/billing/add-plan",
          query: queries_to_this_plan,
        });
      } else {
        this.$router.push({
          path: "/reseller/new-plan",
          query: queries_to_this_plan,
        });
      }
    },

    changeCompanyPlanStatus(newStatus) {
      if (newStatus === "ACTIVE") {
        this.showPlan();
      } else {
        this.$emit("changeCompanyPlanStatus", newStatus);
      }
    },
    showEditDialog(key) {
      switch (key) {
        case "renewal_date":
          this.showEditRenewalDate = true;
          break;
        case "end_trial":
          this.showEditTrial = true;
          break;
        case "payment_method":
          this.showEditPaymentMethod = true;
          break;
        default:
          break;
      }
    },

    updatePlan(data) {
      this.localLoading = true;
      this.plan.end_trial = data.end_trial;
      this.plan.renewal_date = data.renewal_date;
      this.planInitBillingDate = moment(this.plan.end_trial, "YYYY-MM-DD").add(
        1,
        "days"
      );
      this.plan.payment_method = data.payment_method;
      this.snackbarText = this.$t("companyPlansInfo.successEditPlan");
      this.snackbarType = "success";
      this.showSnackbar = true;

      this.closeDialog();
    },

    showSnackbarToast(snackProps) {
      this.snackbarText = snackProps.snackbarText;
      this.snackbarType = snackProps.snackbarType;
      this.showSnackbar = snackProps.showSnackbar;
    },

    closeDialog() {
      this.showEditTrial = false;
      this.showEditRenewalDate = false;
      this.localLoading = false;
      this.showEditPaymentMethod = false;
    },

    editPlan() {
      this.localLoading = true;
      let payload = {
        payment_method:
          this.plan.payment_method === "BANK_SLIP"
            ? "CREDIT_CARD"
            : "BANK_SLIP",
      };
      this.updateHiredPlan({
        payload: payload,
        plan_key: this.plan.key,
      })
        .then(({ data }) => {
          this.updatePlan(data);
        })
        .catch((err) => {
          console.log("updateHiredPlan()", err);
          const snackProps = {
            snackbarText: this.$t("companyPlansInfo.errorEditPlan"),
            snackbarType: "error",
            showSnackbar: true,
          };
          this.showSnackbarToast(snackProps);
        })
        .finally(() => {
          this.localLoading = false;
        });
    },
  },
  created() {
    if (!this.isSelfCheckout) {
      this.getAdvancedLogoutStatus(this.domain).then((res) => {
        this.advancedLogout = res.data.message;
      });
    }
  },

  beforeMount() {
    this.localUpdate(this.plan.notes);
  },
};
</script>
<style scoped>
/* .black-and-white {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}*/
</style>
